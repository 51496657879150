// Modal form send
var modalButton = document.getElementById("send-form-modal");
modalButton.addEventListener("click", function (e) {
    e.preventDefault();
    var routeModal = document.getElementById("route-modal").getAttribute("data-route");
    console.log(routeModal);

    renderLoader(modalButton.closest('.modal-form'));

    let gdpr = null;
    if (document.getElementById('gdpr-modal').checked) gdpr = true;

    let data = {
        name: document.getElementById('name-modal').value,
        email: document.getElementById('email-modal').value,
        phone: document.getElementById('phone-modal').value,
        message: document.getElementById('message-modal').value,
        gdpr: gdpr,
    };

    document.querySelectorAll('.js-form-hidden').forEach((element) => {
        data[ element.getAttribute('name') ] = element.value;
    });

    fetch(routeModal, {
        method: 'POST',
        credentials: "same-origin",
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].getAttribute('content')
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
            removeLoader();
            hideExistedAlertbox();
            if (data.status === 1) {
                document.getElementById("body").insertAdjacentHTML('beforeend', data.html);
                document.getElementById('contactFormModal').reset();
                document.getElementById('modal-invest').classList.add('hide');
                window.location.replace(data.redirect);
                // setTimeout(function () {
                //     document.getElementById('modal-invest').classList.remove('show', 'hide');
                // },500);
                // checkRequiredFields();
            } else {
                document.getElementById("body").insertAdjacentHTML('beforeend', data.html);
                checkRequiredFields();
                Object.keys(data.data).forEach(function(key) {
                    document.getElementById(key + "-modal").classList.add('required');
                });
            }
        })
        .catch(function (error) {
            removeLoader();
        });
});


function hideExistedAlertbox() {
    document.querySelectorAll('.alertbox').forEach(element => {
        element.classList.add('hide');
    });
}

function checkRequiredFields() {
    document.getElementById('contactFormModal').querySelectorAll('input').forEach(element => {
        element.classList.remove('required');
    });
}

function renderLoader(parent) {
    var loader = `
        <div id="js-form-loader" class="form-loader">
            <span class="from-loader-icon"></span>
        </div>
    `;
    parent.insertAdjacentHTML('beforeend', loader);
}

function removeLoader() {
    var loader = document.getElementById('js-form-loader');
    loader.parentElement.removeChild(loader);
}
